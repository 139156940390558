import { LoginOrRegisterPage } from "@web/pages/login/LoginOrRegisterPage";
import Head from "next/head";

export default function Login() {
  return (
    <>
      <Head>
        <title>Harken | Log In</title>
      </Head>
      <LoginOrRegisterPage loginOrRegister="login" />
    </>
  );
}
