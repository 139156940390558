import axios from "axios";
import { Maybe } from "shared";
import { getRestUrl } from "@web/utils/env/getRestUrl";
import { notifyError } from "@ui/index";

type RequestBody = {
  nativeNonce?: string;
  nativeToken?: string;
};

export type ResponseBody = {
  errorDescription?: string;
  errorMessage?: string;
  firebaseToken?: string;
  harkenLoginToken?: string;
  redirect?: string;
  success: boolean;
};

export async function postLoginNative(
  requestBody: RequestBody
): Promise<Maybe<ResponseBody>> {
  try {
    const { data } = await axios.post<ResponseBody>(
      getRestUrl("login/native"),
      requestBody,
      {
        // Needed so session cookie gets set
        withCredentials: true,
      }
    );
    return data;
  } catch (e) {
    notifyError((e as Error).message);
    return null;
  }
}
