import { useGoogleIdentityScript } from "@web/hooks/scripts/useGoogleIdentityScript";
import { useEffect } from "react";
import { ElementId, notifyError } from "ui";
import { GOOGLE_OAUTH_CLIENT_ID } from "shared";
import { postLoginGoogle } from "@web/utils/rest/login/postLoginGoogle";
import { firebaseLogin } from "@web/utils/firebase/auth/firebaseLogin";
import { useViewerContext } from "@web/hooks/contexts/useViewerContext";
import { padUrlParamsWithToken } from "@web/utils/auth/PadUrlParamsWithToken";

declare global {
  interface Window {
    google: any;
  }
}

// See https://developers.google.com/identity/gsi/web/guides/overview
// for more info about Google auth.
export function GoogleLoginButton() {
  const status = useGoogleIdentityScript();
  const { setIsLoggingIn } = useViewerContext();

  useEffect(() => {
    if (status === "ready") {
      window.google.accounts.id.initialize({
        callback: async (response: any) => {
          const data = await postLoginGoogle({
            idToken: response.credential,
          });

          if (data == null) {
            notifyError();
            return;
          }

          if (data.firebaseToken != null) {
            try {
              setIsLoggingIn(true);
              await firebaseLogin(data.firebaseToken);
            } catch (e) {
              setIsLoggingIn(false);
              notifyError((e as Error).message);
              return;
            }
          }

          if (data.redirect != null) {
            window.location.href = padUrlParamsWithToken(data);
            return;
          }

          if (data.errorDescription != null) {
            notifyError(data.errorDescription, data.errorMessage);
          }
        },
        client_id: GOOGLE_OAUTH_CLIENT_ID,
      });

      const parent = document.getElementById(ElementId.GoogleLoginButton);
      window.google.accounts.id.renderButton(parent, {
        shape: "pill",
        size: "large",
        theme: "outline",
      });
    }
  }, [setIsLoggingIn, status]);

  return <div id={ElementId.GoogleLoginButton} />;
}
