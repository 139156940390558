import styles from "@web/pages/login/css/LoginOrRegisterPage.module.css";
import {
  PageBody,
  Body1Medium,
  ColorClass,
  Body1,
  TextButton,
  FontClass,
  notifyError,
  notify,
} from "ui";
import { LoginOrRegisterEmailForm } from "@web/components/login/LoginOrRegisterEmailForm";
import { LoginOrRegisterNonceForm } from "@web/components/login/LoginOrRegisterNonceForm";
import { useEffect, useState } from "react";
import { LoginOrRegister } from "@web/types/LoginOrRegister";
import { useQueryParams } from "@web/hooks/useQueryParams";
import { RegisterCreateAccount } from "@web/components/login/RegisterCreateAccount";
import { RedirectIfLoggedInContainer } from "@web/components/auth/RedirectIfLoggedInContainer";
import { GoogleLoginButton } from "@web/components/auth/GoogleLoginButton";
import { Maybe, UrlParam } from "shared";
import { useRouter } from "next/router";
import { PageWithHeaderAndFooter } from "@web/components/containers/PageWithHeaderAndFooter";
import AuthHeader from "@web/components/headers/AuthHeader";
import { useViewerContext } from "@web/hooks/contexts/useViewerContext";
import { firebaseLogin } from "@web/utils/firebase/auth/firebaseLogin";
import { postLoginNative } from "@web/utils/rest/login/postLoginNative";
import { padUrlParamsWithToken } from "@web/utils/auth/PadUrlParamsWithToken";

type Props = {
  loginOrRegister: LoginOrRegister;
};

function LoginOrRegisterContent({
  loginOrRegister,
  showNonceForm,
  setShowNonceForm,
}: Props & {
  setShowNonceForm: (val: boolean) => void;
  showNonceForm: boolean;
}) {
  const form = showNonceForm ? (
    <LoginOrRegisterNonceForm loginOrRegister={loginOrRegister} />
  ) : (
    <LoginOrRegisterEmailForm
      loginOrRegister={loginOrRegister}
      onSuccess={() => setShowNonceForm(true)}
    />
  );
  return (
    <div className={styles.form}>
      {form}
      <div className={styles.separator}>
        <div className={styles.separatorLine} />
        <Body1Medium
          className={styles.separatorText}
          colorClass={ColorClass.Ghost}
          textTransform="uppercase"
        >
          Or
        </Body1Medium>
        <div className={styles.separatorLine} />
      </div>
      <div className={styles.googleLoginButtonContainer}>
        <GoogleLoginButton />
      </div>
    </div>
  );
}

function Container({
  children,
  loginOrRegister,
  showNonceForm,
}: {
  children: any;
  loginOrRegister: LoginOrRegister;
  showNonceForm: boolean;
}) {
  const headerText = showNonceForm
    ? "Enter the Emailed Code"
    : loginOrRegister === "login"
    ? "Sign In"
    : "Get Started";

  return (
    <PageWithHeaderAndFooter header={<AuthHeader />}>
      <PageBody header={headerText}>{children}</PageBody>
    </PageWithHeaderAndFooter>
  );
}

function _Placeholder() {
  return (
    <PageWithHeaderAndFooter header={<AuthHeader />}>
      <PageBody header="We're still working!">
        <Body1
          colorClass={ColorClass.Primary}
          textAlign="center"
          style={{ margin: "auto", maxWidth: 900 }}
        >
          Harken is still under active development—please follow{" "}
          <TextButton
            buttonThemeOrColorClass={ColorClass.Highlight}
            display="inline"
            fontClass={FontClass.Body1}
            href="https://twitter.com/pencilflip"
            type="link_external"
          >
            @pencilflip
          </TextButton>{" "}
          on Twitter if you&apos;d like to stay updated.
          <br />
          <br />
          We are aiming to launch in late April, 2023.
        </Body1>
      </PageBody>
    </PageWithHeaderAndFooter>
  );
}
export function LoginOrRegisterPage({ loginOrRegister }: Props) {
  const { pathname } = useRouter();
  const params = useQueryParams();
  const [showNonceForm, setShowNonceForm] = useState<boolean>(false);
  const [_isBypassPlaceholder, setIsBypassPlaceholder] = useState(false);
  const [_isNativeLoggingIn, setIsNativeLoggingIn] = useState(false);
  const { setIsLoggingIn } = useViewerContext();

  useEffect(() => {
    setIsBypassPlaceholder(params.bypassPlaceholder != null);
  }, [params.bypassPlaceholder]);

  const nonce = params[UrlParam.Nonce] as Maybe<string>;
  const googleIdToken = params[UrlParam.GoogleIdToken] as Maybe<string>;
  const firebaseToken = params[UrlParam.FirebaseToken] as Maybe<string>;

  async function _Login(nativeToken: string) {
    notify({
      description: "Logging in with token",
      message: "Token found!",
      type: "info",
    });

    const data = await postLoginNative({
      nativeToken: nativeToken,
    });
    if (data == null) {
      notifyError();
      return;
    }
    if (data.firebaseToken != null) {
      try {
        setIsLoggingIn(true);
        await firebaseLogin(data.firebaseToken);
      } catch (e) {
        setIsLoggingIn(false);
        notifyError((e as Error).message);
        return;
      }
    }
    if (data.redirect != null) {
      window.location.href = padUrlParamsWithToken(data);
      return;
    }

    if (data.errorDescription != null) {
      notifyError(data.errorDescription, data.errorMessage);
    }
  }

  useEffect(() => {
    if (firebaseToken != null) {
      setIsNativeLoggingIn(true);
      // TODO YIFEI show logging in
      // showflywheel()
      _Login(firebaseToken);

      setIsNativeLoggingIn(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, firebaseToken]);
  // if (
  //   isProd() &&
  //   !isBypassPlaceholder &&
  //   nonce == null &&
  //   googleIdToken == null
  // ) {
  //   return <Placeholder />;
  // }

  if ((nonce != null || googleIdToken != null) && pathname === "/register") {
    return (
      <RedirectIfLoggedInContainer>
        <Container
          loginOrRegister={loginOrRegister}
          showNonceForm={showNonceForm}
        >
          <div className={styles.form}>
            <RegisterCreateAccount
              createAccountNonce={nonce}
              googleIdToken={googleIdToken}
            />
          </div>
        </Container>
      </RedirectIfLoggedInContainer>
    );
  }

  return (
    <RedirectIfLoggedInContainer>
      <Container
        loginOrRegister={loginOrRegister}
        showNonceForm={showNonceForm}
      >
        <LoginOrRegisterContent
          loginOrRegister={loginOrRegister}
          showNonceForm={showNonceForm}
          setShowNonceForm={setShowNonceForm}
        />
      </Container>
    </RedirectIfLoggedInContainer>
  );
}
